import { Image } from '@heroui/react';

export default function ClientLogo() {
  const clientLogos = [
    'https://lacasatour.b-cdn.net/assets/client-logo/cl1.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl2.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl3.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl4.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl5.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl6.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl7.png',
    'https://lacasatour.b-cdn.net/assets/client-logo/cl8.png',
  ];
  return (
    <div>
      <div className="flex justify-center">
        <div className="grid w-full grid-cols-8 gap-2">
          {clientLogos.map((logo, index) => (
            <div
              key={index}
              className="group relative flex items-center justify-center overflow-hidden rounded-lg"
            >
              <Image
                src={logo}
                alt="client logo"
                width={125}
                height={125}
                // Let them grow/shrink to fill the parent, maintain ratio, no stretching
                className="
            h-auto
            w-full
            object-contain
            grayscale
            transition-all
            duration-300
            group-hover:scale-105
            group-hover:grayscale-0
          "
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
