import { Button, Image } from '@heroui/react';
import { Play } from 'lucide-react';
import { useEffect, useState } from 'react';

const TestimonialSlider = () => {
  const images = [
    'https://plus.unsplash.com/premium_photo-1681822627106-2616801638c3?q=80&w=1881&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1614786269829-d24616faf56d?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1630705344895-eb7b1bde9221?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1584273143981-41c073dfe8f8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1620511469298-7c119cc6982c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  ];
  const totalImages = images.length;
  const sliderSpeed = totalImages * 15;
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  useEffect(() => {
    if (currentImageIndex !== null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [currentImageIndex]);

  // Open image modal and normalize index
  const handleImageClick = (index) => {
    const normalizedIndex = index % totalImages;
    setCurrentImageIndex(normalizedIndex);
  };

  // Close modal
  const closeModal = () => {
    setCurrentImageIndex(null);
  };

  // Navigate to the previous image
  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : totalImages - 1));
  };

  // Navigate to the next image
  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % totalImages);
  };

  return (
    <div>
      <div id="photos" className="flex flex-col overflow-hidden">
        {/* First Slider - Scroll Right */}
        <div className="relative w-full overflow-hidden">
          <div
            className="slider slider-right flex"
            style={{ animationDuration: `${sliderSpeed}s` }}
          >
            {images.concat(images).map((img, idx) => (
              <div key={idx} className="relative">
                <Image
                  src={img}
                  alt={`slider-${idx}`}
                  className="aspect-[9/16] h-[30rem] cursor-pointer object-cover p-1"
                  onClick={() => handleImageClick(idx)}
                />
                <div className="absolute bottom-0 left-0 z-10 flex w-full items-center justify-between bg-gradient-to-t from-black to-transparent p-4">
                  <div className="text-white">
                    <p className="font-semibold">Client Name</p>
                    <p className="text-sm">Company Name</p>
                  </div>
                  <div
                    radius="full"
                    className="flex h-10 w-10 items-center justify-center bg-white/10 text-lime-400 rounded-full"
                  >
                    <Play className="h-8 w-8 animate-pulse" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Second Slider - Scroll Left */}
        {/* <div className="relative w-full overflow-hidden">
          <div className="slider slider-left flex" style={{ animationDuration: `${sliderSpeed}s` }}>
            {images.concat(images).map((img, idx) => (
              <img
                key={`left-${idx}`}
                src={img}
                alt={`slider-left-${idx}`}
                onClick={() => handleImageClick(idx)}
                className="slider-image h-[200px] w-[290px] cursor-pointer object-cover p-1 md:h-[250px] md:w-[400px]"
              />
            ))}
          </div>
        </div> */}
      </div>

      {/* Modal */}
      {currentImageIndex !== null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
          {/* Close Button */}
          <button
            className="absolute right-4 top-4 text-3xl font-bold text-white"
            onClick={closeModal}
          >
            ×
          </button>

          {/* Image Count */}
          <div className="absolute left-8 top-4 -translate-x-1/2 transform text-lg text-white">
            {currentImageIndex + 1}/{images.length}
          </div>

          {/* Image */}
          <div className="relative">
            <img
              src={images[currentImageIndex]}
              alt={`Image ${currentImageIndex + 1}`}
              className="max-h-screen max-w-full p-10"
            />
          </div>

          {/* Left Button */}
          <button className="absolute left-4 text-3xl font-bold text-white" onClick={prevImage}>
            ‹
          </button>

          {/* Right Button */}
          <button className="absolute right-4 text-3xl font-bold text-white" onClick={nextImage}>
            ›
          </button>
        </div>
      )}
    </div>
  );
};

export default TestimonialSlider;
